import { Api } from '../common/apis';

import * as Types from './CommentAPIs.types';

const apis = new Api(`/api/v1/comments`);

export const getComments = async (
    request: Types.GetCommentsRequest,
): Promise<Types.GetCommentsResponse> => {
    return await apis.get<Types.GetCommentsRequest, Types.GetCommentsResponse>(
        'getComments',
        request,
    );
};
export const getNumberOfCommentsOnVideo = async (
    request: Types.GetNumberOfCommentsOnVideoRequest,
): Promise<Types.GetNumberOfCommentsOnVideoResponse> => {
    return await apis.get<
        Types.GetNumberOfCommentsOnVideoRequest,
        Types.GetNumberOfCommentsOnVideoResponse
    >('getNumberOfCommentsOnVideo', request);
};
export const getCommentsForChannel = async (
    request: Types.GetCommentsForChannelRequest,
): Promise<Types.GetCommentsForChannelResponse> => {
    return await apis.get<
        Types.GetCommentsForChannelRequest,
        Types.GetCommentsForChannelResponse
    >('getCommentsForChannel', request);
};

export const getNumberOfCommentsOnChannel =
    async (): Promise<Types.GetNumberOfCommentsForChannelResponse> => {
        return await apis.get<
            Types.GetNumberOfCommentsOnChannelRequest,
            Types.GetNumberOfCommentsForChannelResponse
        >('getNumberOfCommentsOnChannel');
    };

export const addComment = async (
    request: Types.AddCommentRequest,
): Promise<Types.AddCommentResponse> => {
    return await apis.post<Types.AddCommentRequest, Types.AddCommentResponse>(
        'addComment',
        request,
    );
};
export const addReply = async (
    request: Types.AddReplyRequest,
): Promise<Types.AddReplyResponse> => {
    return await apis.post<Types.AddReplyRequest, Types.AddReplyResponse>(
        'addReply',
        request,
    );
};
export const deleteComment = async (
    request: Types.DeleteCommentRequest,
): Promise<Types.DeleteCommentResponse> => {
    return await apis.delete<
        Types.DeleteCommentRequest,
        Types.DeleteCommentResponse
    >('deleteComment', request);
};
export const getReplies = async (
    request: Types.GetRepliesRequest,
): Promise<Types.GetRepliesResponse> => {
    return await apis.get<Types.GetRepliesRequest, Types.GetRepliesResponse>(
        'getReplies',
        request,
    );
};
export const updateComment = async (
    request: Types.UpdateCommentRequest,
): Promise<Types.UpdateCommentResponse> => {
    return await apis.put<
        Types.UpdateCommentRequest,
        Types.UpdateCommentResponse
    >('updateComment', request);
};

export const addCommentLikeDislike = async (
    request: Types.AddCommentLikeDislikeRequest,
): Promise<Types.AddCommentLikeDislikeResponse> => {
    return await apis.put<
        Types.AddCommentLikeDislikeRequest,
        Types.AddCommentLikeDislikeResponse
    >('addLikeDislike', request);
};

export const removeCommentLikeDislike = async (
    request: Types.RemoveCommentLikeDislikeRequest,
): Promise<Types.RemoveCommentLikeDislikeResponse> => {
    return await apis.delete<
        Types.RemoveCommentLikeDislikeRequest,
        Types.RemoveCommentLikeDislikeResponse
    >('removeLikeDislike', request);
};

export const getCommentLikeDislikeStatus = async (
    request: Types.GetCommentLikeDislikeStatusRequest,
): Promise<Types.GetCommentLikeDislikeStatusResponse> => {
    return await apis.get<
        Types.GetCommentLikeDislikeStatusRequest,
        Types.GetCommentLikeDislikeStatusResponse
    >('getLikeDislikeStatus', request);
};
