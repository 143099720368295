import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TableBody,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
    getVideosCountForPersonalChannel,
    GetVideosCountForPersonalChannelResponse,
    getVideosForPersonalChannel,
    GetVideosForPersonalChannelResponse,
    MediaMetadata,
} from 'apis';
import { useQuery } from '@tanstack/react-query';
import VideoEditTableSkeleton from './VideoEditTableSkeleton';
import VideoEditDropdown from './VideoEditDropdown';
import { Link } from 'react-router-dom';
import { formatMsDurationAsHHmmss, PaginatedTable } from 'ui';

type Order = 'asc' | 'desc';

interface Data {
    Video: string;
    Visibility: string;
    Restrictions: string;
    Date: Date;
    Views: number;
    Comments: number;
    Likes: number;
    Duration: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headers: HeadCell[] = [
    {
        id: 'Video',
        label: 'Video',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Visibility',
        label: 'Visibility',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Restrictions',
        label: 'Restrictions',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Date',
        label: 'Date',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Views',
        label: 'Views',
        numeric: true,
        disablePadding: true,
    },
    {
        id: 'Comments',
        label: 'Comments',
        numeric: true,
        disablePadding: true,
    },
    {
        id: 'Likes',
        label: 'Likes (vs dislikes)',
        numeric: true,
        disablePadding: true,
    },
    {
        id: 'Duration',
        label: 'Duration',
        numeric: true,
        disablePadding: true,
    },
];

interface VideoEditTableHeadProps {
    orderBy: keyof Data;
    order: Order;
}

const VideoEditTableHead: React.FC<VideoEditTableHeadProps> = (props) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox color="primary" />
                </TableCell>
                {headers.map((header) => (
                    <TableCell
                        key={header.id}
                        align={header.numeric ? 'right' : 'left'}
                        padding={header.disablePadding ? 'none' : 'normal'}
                        sortDirection={
                            props.orderBy === header.id ? props.order : false
                        }
                    >
                        <TableSortLabel
                            active={props.orderBy === header.id}
                            direction={
                                props.orderBy === header.id
                                    ? props.order
                                    : 'asc'
                            }
                        >
                            {header.label}
                            <Box component="span" sx={visuallyHidden}>
                                {props.order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                            </Box>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const VideoEditTableBody: React.FC<{
    videos: MediaMetadata[];
    channelId: string;
}> = (props) => {
    return (
        <TableBody>
            {props.videos.map((video) => (
                <TableRow key={video.title}>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            inputProps={{
                                'aria-labelledby': video.title,
                            }}
                        />
                    </TableCell>
                    <TableCell
                        component="th"
                        id={video.title}
                        scope="row"
                        padding="none"
                    >
                        {video.title}{' '}
                        <VideoEditDropdown
                            videoId={video._id}
                            title={video.title}
                            channelId={props.channelId}
                        />
                    </TableCell>
                    <TableCell
                        component="th"
                        id={video.title}
                        scope="row"
                        padding="none"
                    >
                        {video.audience}
                    </TableCell>
                    <TableCell
                        component="th"
                        id={video.title}
                        scope="row"
                        padding="none"
                    >
                        {video.restricted ? '18 and up' : 'For Kids'}
                    </TableCell>
                    <TableCell
                        component="th"
                        id={video.title}
                        scope="row"
                        padding="none"
                    >
                        {video.createDate.toString()}
                    </TableCell>
                    <TableCell align="right">{video.views ?? 0}</TableCell>
                    <TableCell align="right">
                        <Link
                            to={`${video._id}/comments`}
                            style={{
                                fontSize: 'inherit',
                            }}
                        >
                            {video.comments ?? 0}
                        </Link>
                    </TableCell>
                    <TableCell align="right">0</TableCell>
                    <TableCell align="right">
                        {formatMsDurationAsHHmmss(video?.duration ?? 0)}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

interface VideoEditTableProps {
    orderBy: keyof Data;
    order: Order;
    channelId: string;
}

const VideoEditTable: React.FC<VideoEditTableProps> = (props) => {
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const { isLoading, isError, data } =
        useQuery<GetVideosForPersonalChannelResponse>(
            [`get-personal-videos-${props.channelId}`, page, limit],
            () => getVideosForPersonalChannel({ page: page, limit: limit }),
            {
                refetchInterval: false,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                keepPreviousData: true,
            },
        );
    const numberOfVideosQuery =
        useQuery<GetVideosCountForPersonalChannelResponse>(
            ['get-number-of-videos-for-channel'],
            async () => getVideosCountForPersonalChannel(),
            {
                refetchInterval: false,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
            },
        );
    return (
        <PaginatedTable
            isLoading={isLoading || numberOfVideosQuery.isLoading}
            isError={isError || numberOfVideosQuery.isError}
            data={data?.videos ?? []}
            count={numberOfVideosQuery.data?.count ?? 0}
            header={() => (
                <VideoEditTableHead orderBy={props.orderBy} order={'asc'} />
            )}
            body={() => (
                <VideoEditTableBody
                    videos={data?.videos ?? []}
                    channelId={props.channelId}
                />
            )}
            loadingSkeleton={VideoEditTableSkeleton}
            limit={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
};

export default VideoEditTable;
