import { Api } from '../common/apis';

import * as Types from './MediaAPIs.types';

const apis = new Api(`/api/v1/media`);

export const getMediaById = async (
    request: Types.GetMediaByIdRequest,
): Promise<Types.GetMediaByIdResponse> => {
    const response = transformResponseWithDateObjects(
        await apis.get<Types.GetMediaByIdRequest, Types.GetMediaByIdResponse>(
            'getMediaById',
            request,
        ),
    );
    return response;
};
export const addMedia = async (
    request: Types.AddMediaRequest,
): Promise<Types.AddMediaResponse> => {
    return transformResponseWithDateObjects(
        await apis.post<Types.AddMediaRequest, Types.AddMediaResponse>(
            'addMedia',
            request,
        ),
    );
};
export const updateMedia = async (
    request: Types.UpdateMediaRequest,
): Promise<Types.UpdateMediaResponse> => {
    return await apis.post<Types.UpdateMediaRequest, Types.UpdateMediaResponse>(
        'updateMedia',
        request,
    );
};
export const getRelatedVideos = async (
    request: Types.GetRelatedVideosRequest,
): Promise<Types.GetRelatedVideosResponse> => {
    const response = await apis.get<
        Types.GetRelatedVideosRequest,
        Types.GetRelatedVideosResponse
    >('getRelatedVideos', request);
    return {
        ...response,
        videos: response.videos.map((media) =>
            transformResponseWithDateObjects(media),
        ),
    };
};

export const deleteMedia = async (
    request: Types.DeleteMediaRequest,
): Promise<Types.DeleteMediaResponse> => {
    return await apis.delete<
        Types.DeleteMediaRequest,
        Types.DeleteMediaResponse
    >('deleteMedia', request);
};
export const getVideosForChannel = async (
    request: Types.GetVideosForChannelRequest,
): Promise<Types.GetVideosForChannelResponse> => {
    const response = await apis.get<
        Types.GetVideosForChannelRequest,
        Types.GetVideosForChannelResponse
    >('getVideosForChannel', request);
    return {
        ...response,
        videos: response.videos.map((media) =>
            transformResponseWithDateObjects(media),
        ),
    };
};
export const getVideos = async (
    request: Types.GetVideosRequest,
): Promise<Types.GetVideosResponse> => {
    const response = await apis.get<
        Types.GetVideosRequest,
        Types.GetVideosResponse
    >('getVideos', request);
    return {
        ...response,
        videos: response.videos.map((media) =>
            transformResponseWithDateObjects(media),
        ),
    };
};
export const addView = async (
    request: Types.AddViewRequest,
): Promise<Types.AddViewResponse> => {
    return await apis.put<Types.AddViewRequest, Types.AddViewResponse>(
        'addView',
        request,
    );
};

export const addMediaLikeDislike = async (
    request: Types.AddMediaLikeDislikeRequest,
): Promise<Types.AddMediaLikeDislikeResponse> => {
    return await apis.put<
        Types.AddMediaLikeDislikeRequest,
        Types.AddMediaLikeDislikeResponse
    >('addLikeDislike', request);
};

export const removeMediaLikeDislike = async (
    request: Types.RemoveMediaLikeDislikeRequest,
): Promise<Types.RemoveMediaLikeDislikeResponse> => {
    return await apis.delete<
        Types.RemoveMediaLikeDislikeRequest,
        Types.RemoveMediaLikeDislikeResponse
    >('removeLikeDislike', request);
};
export const getVideosForPersonalChannel =
    async (request: Types.GetVideosForPersonalChannelRequest): Promise<Types.GetVideosForPersonalChannelResponse> => {
        const response = await apis.get<
            Types.GetVideosForPersonalChannelRequest,
            Types.GetVideosForPersonalChannelResponse
        >('getVideosForPersonalChannel', request);
        return {
            ...response,
            videos: response.videos.map((media) =>
                transformResponseWithDateObjects(media),
            ),
        };
    };
export const getVideosCountForPersonalChannel =
async (): Promise<Types.GetVideosCountForPersonalChannelResponse> => {
    const response = await apis.get<
    Types.GetVideosCountForPersonalChannelRequest,
    Types.GetVideosCountForPersonalChannelResponse
    >('getVideosCountForPersonalChannel');
    return response;
}

export const getMediaLikeDislikeStatus = async (
    request: Types.GetLikeDislikeStatusRequest,
): Promise<Types.GetLikeDislikeStatusResponse> => {
    return await apis.get<
        Types.GetLikeDislikeStatusRequest,
        Types.GetLikeDislikeStatusResponse
    >('getLikeDislikeStatus', request);
};

export const getPlaylist = async (request: Types.GetPlaylistRequest) => {
    return await apis.get<Types.GetPlaylistRequest, Types.GetPlayListResponse>(
        'getPlaylist',
        request,
    );
};

export const getUploadProgress = async (
    request: Types.GetUploadProgressRequest,
): Promise<Types.GetUploadProgressResponse> => {
    return await apis.get<
        Types.GetUploadProgressRequest,
        Types.GetUploadProgressResponse
    >('getUploadProgress', request);
};

export const getUploadingVideosForChannel =
    async (): Promise<Types.GetUploadingVideosForChannelResponse> => {
        return await apis.get<
            Types.GetUploadingVideosForChannelRequest,
            Types.GetUploadingVideosForChannelResponse
        >('getUploadingVideosForChannel');
    };

const getVideoUploadLink = async (
    request: Types.GetVideoUploadLinkRequest,
): Promise<Types.GetVideoUploadLinkResponse> => {
    return await apis.get<
        Types.GetVideoUploadLinkRequest,
        Types.GetVideoUploadLinkResponse
    >('getVideoUploadLink', request);
};

const getImageUploadLink = async (
    request: Types.GetImageUploadLinkRequest,
): Promise<Types.GetImageUploadLinkResponse> => {
    return await apis.get<
        Types.GetImageUploadLinkRequest,
        Types.GetImageUploadLinkResponse
    >('getImageUploadLink', request);
};

const uploadImage = async (
    request: { url: string; file: File },
    onUploadProgress?: (data: { loaded: number; total: number }) => void,
): Promise<any> => {
    return await fetch(request.url, {
        method: 'PUT',
        body: request.file,
        headers: {
            ContentType: request.file.type,
        },
    });
};

const uploadVideo = async (
    request: { url: string; file: File },
    onUploadProgress?: (data: { loaded: number; total: number }) => void,
): Promise<any> => {
    return await fetch(request.url, {
        method: 'PUT',
        body: request.file,
        headers: {
            ContentType: request.file.type,
            ContentLength: request.file.size.toString(),
        },
    });
};

export const uploadPresignedVideoUrl = async (
    videoId: string,
    title: string,
    file: File,
    onUploadProgress?: (data: { loaded: number; total: number }) => void,
) => {
    const response = await getVideoUploadLink({
        videoId: videoId,
        title: title,
        fileName: file.name,
        fileType: file.type,
    });
    return await uploadVideo(
        { url: response.url, file: file },
        onUploadProgress,
    );
};

export const uploadPresignedImageUrl = async (
    path: string,
    file: File,
    onUploadProgress?: (data: { loaded: number; total: number }) => void,
) => {
    const response = await getImageUploadLink({
        path: path,
        fileName: file.name,
        fileType: file.type,
    });
    return await uploadImage(
        { url: response.url, file: file },
        onUploadProgress,
    );
};

const transformResponseWithDateObjects = (media: Types.MediaMetadata) => {
    media.createDate = new Date(media.createDate);
    if (media.updateDate) {
        media.updateDate = new Date(media.updateDate);
    }
    return media;
};
