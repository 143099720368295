import React, { useState } from 'react';
import {
    getComments,
    GetCommentsResponse,
    Comment,
    GetNumberOfCommentsOnVideoResponse,
    getNumberOfCommentsOnVideo,
} from 'apis';
import {
    Box,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TableBody,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';
import VideoCommentTableSkeleton from './VideoCommentTableSkeleton';
import { PaginatedTable } from 'ui';

type Order = 'asc' | 'desc';

interface Data {
    User: string;
    Comment: string;
    Likes: string;
    Dislikes: string;
    Replies: string;
    CreatedOn: string;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headers: HeadCell[] = [
    {
        id: 'User',
        label: 'User',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Comment',
        label: 'Comment',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Likes',
        label: 'Likes',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Dislikes',
        label: 'Dislikes',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Replies',
        label: 'Replies',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'CreatedOn',
        label: 'Date',
        numeric: false,
        disablePadding: true,
    },
];

interface VideoEditTableHeadProps {
    orderBy: keyof Data;
    order: Order;
}

const VideoCommentsTableHead: React.FC<VideoEditTableHeadProps> = (props) => {
    return (
        <TableHead>
            <TableRow>
                {headers.map((header) => (
                    <TableCell
                        key={header.id}
                        align={header.numeric ? 'right' : 'left'}
                        padding={header.disablePadding ? 'none' : 'normal'}
                        sortDirection={
                            props.orderBy === header.id ? props.order : false
                        }
                    >
                        <TableSortLabel
                            active={props.orderBy === header.id}
                            direction={
                                props.orderBy === header.id
                                    ? props.order
                                    : 'asc'
                            }
                        >
                            {header.label}
                            <Box component="span" sx={visuallyHidden}>
                                {props.order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                            </Box>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

interface VideoCommentsTableProps {
    videoId: string;
}

const VideoCommentsTableBody: React.FC<{ comments: Comment[] }> = (props) => {
    return (
        <TableBody>
            {props.comments.map((comment) => (
                <TableRow key={comment._id}>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {comment.username}
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {comment.body}
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {comment.likes}
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {comment.dislikes}
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {comment.replies}
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {comment.createDate.toString()}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

const VideoCommentsTable: React.FC<VideoCommentsTableProps> = (props) => {
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const commentsSizeOnVideoQuery =
        useQuery<GetNumberOfCommentsOnVideoResponse>(
            [`get-video-${props.videoId}-comments-count`],
            () => getNumberOfCommentsOnVideo({ sourceId: props.videoId }),
            {
                refetchInterval: Infinity,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
        );
    const commentsForChannel = useQuery<GetCommentsResponse>(
        [`video-${props.videoId}-comments`, page, limit],
        () =>
            getComments({ sourceId: props.videoId, page: page, limit: limit }),
        {
            refetchInterval: Infinity,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            keepPreviousData: true,
        },
    );
    return (
        <PaginatedTable
            isLoading={
                commentsForChannel.isLoading ||
                commentsSizeOnVideoQuery.isLoading
            }
            isError={
                commentsForChannel.isError || commentsSizeOnVideoQuery.isError
            }
            data={commentsForChannel.data?.comments ?? []}
            count={commentsSizeOnVideoQuery?.data?.size ?? 0}
            header={() => (
                <VideoCommentsTableHead orderBy={'User'} order={'asc'} />
            )}
            body={() => (
                <VideoCommentsTableBody
                    comments={commentsForChannel.data?.comments ?? []}
                />
            )}
            loadingSkeleton={VideoCommentTableSkeleton}
            limit={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
};

export default VideoCommentsTable;
