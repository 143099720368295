import React from 'react';
import {
    Stack,
    Paper,
    Table,
    TableBody,
    TableContainer,
    Typography,
    TableBodyTypeMap,
    TablePagination,
    TableRow,
    TableCell,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface PaginatedTableProps {
    isLoading: boolean;
    isError: boolean;
    data: any[];
    count: number;
    header: React.FC<any>;
    body: OverridableComponent<TableBodyTypeMap<any, 'tbody'>>;
    loadingSkeleton: React.FC<
        React.PropsWithChildren<{ isLoading: boolean; isError: boolean }>
    >;
    limit: number;
    page: number;
    onPageChange: (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => void;
    onRowsPerPageChange:
        | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
        | undefined;
}

const NoResultsElement: React.FC = () => (
    <TableBody>
        <TableRow>
            <TableCell colSpan={5}>
                <Stack alignItems={'center'} sx={{ width: '100%' }} spacing={2}>
                    <Typography>No Results</Typography>
                </Stack>
            </TableCell>
        </TableRow>
    </TableBody>
);

export const PaginatedTable: React.FC<PaginatedTableProps> = (props) => {
    return (
        <Stack>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <Table>
                        <props.header />
                        <props.loadingSkeleton
                            isLoading={props.isLoading}
                            isError={props.isError}
                        >
                            {props.data.length === 0 ? (
                                <NoResultsElement />
                            ) : (
                                <props.body />
                            )}
                        </props.loadingSkeleton>
                    </Table>
                </TableContainer>
            </Paper>
            <Paper>
                <TablePagination
                    component="div"
                    count={props.count}
                    page={props.page}
                    onPageChange={props.onPageChange}
                    rowsPerPage={props.limit}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                />
            </Paper>
        </Stack>
    );
};
