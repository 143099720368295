import React from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';

export interface ReactHlsPlayer
    extends React.VideoHTMLAttributes<HTMLVideoElement> {
    src: string;
    autoPlay: boolean;
    hls?: boolean;
    onVideoProgress?: (state: OnProgressProps) => void;
    onPlay?: () => void;
    onPause?: () => void;
    onBuffer?: () => void;
    onEnded?: () => void;
    onDuration?: (duration: number) => void;
    onSeek?: (seconds: number) => void;
}

export const ReactHlsPlayer: React.FC<ReactHlsPlayer> = (props) => {
    return (
        <div className="player-wrapper">
            <ReactPlayer
                className="react-player"
                url={props.src}
                config={{
                    file: {
                        forceHLS: props.hls === undefined || props.hls === true,
                        forceVideo: true,
                        hlsOptions: {
                            autoStartLoad: true,
                            startPosition: -1,
                        },
                    },
                }}
                width="100%"
                height="100%"
                playing
                controls={true}
                onProgress={props.onVideoProgress}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onBuffer={props.onBuffer}
                onEnded={props.onEnded}
                onDuration={props.onDuration}
                onSeek={props.onSeek}
            />
        </div>
    );
};
