import React, { useEffect } from 'react';
import { AccountProvider, ModalProvider, useAccountContext } from 'hooks';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import ChannelRouter from './pages/channel/[channelId]/ChannelRouter';
import LoginPage from './pages/Login';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Layout from './components/common/Layout';
import { getSession } from 'apis';
const bluechip_studio_version = import.meta.env.VITE_VERSION_RELEASE;
const queryClient = new QueryClient();
function App() {
    console.debug('version', bluechip_studio_version);
    useEffect(() => {
        getSession();
    }, []);
    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <AccountProvider>
                    <ModalProvider>
                        <Layout>
                            <Routes>
                                <Route
                                    path="/"
                                    element={<Navigate replace to="/create" />}
                                />
                                <Route
                                    path="/bluechip"
                                    element={
                                        <Redirect url="https://www.bluechip.link" />
                                    }
                                />
                                <Route path="/create" element={<div>hi</div>} />
                                <Route
                                    path="/channel/:username/home"
                                    element={
                                        <Redirect url="https://www.bluechip.link" />
                                    }
                                />
                                <Route
                                    path="/create/channel/*"
                                    element={<ChannelRouter />}
                                />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/logout" element={<Logout />} />
                            </Routes>
                        </Layout>
                    </ModalProvider>
                </AccountProvider>
            </QueryClientProvider>
        </Router>
    );
}

const Redirect: React.FC<{ url: string }> = (props) => {
    const location = useLocation();
    window.location.href = `${props.url}${location.pathname}`;
    return null;
};

const Logout: React.FC = () => {
    const navigate = useNavigate();
    const { logout } = useAccountContext();
    useEffect(() => {
        logout().then(() => navigate('/home'));
    }, []);
    return <></>;
};

export default App;
