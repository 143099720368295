import React, { useMemo, useState } from 'react';
import {
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ChannelViewCommentsTableSkeleton from './ChannelViewCommentsTableSkeleton';
import {
    Comment,
    getCommentsForChannel,
    GetCommentsForChannelResponse,
    GetNumberOfCommentsForChannelResponse,
    getNumberOfCommentsOnChannel,
} from 'apis';
import { useQuery } from '@tanstack/react-query';
import { traceSpan } from '../../../../../telemetry/tracing';
import { PaginatedTable } from 'ui';

interface Data {
    // Comment: string;
    VideoTitle: string;
    Username: string;
    CreatedOn: Date;
    CommentBody: string;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headers: HeadCell[] = [
    // {
    //     id: 'Comment',
    //     label: 'Select',
    //     numeric: false,
    //     disablePadding: true,
    // },
    {
        id: 'Username',
        label: 'User',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'VideoTitle',
        label: 'Video Title',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'CreatedOn',
        label: 'Date',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'CommentBody',
        label: 'Comments',
        numeric: false,
        disablePadding: true,
    },
];
type Order = 'asc' | 'desc';
interface ChannelViewCommentsTableHeadProps {
    orderBy: keyof Data;
    order: Order;
}

const ChannelViewCommentsPageTableHeader: React.FC<
    ChannelViewCommentsTableHeadProps
> = (props) => {
    return (
        <TableHead>
            <TableRow>
                {headers.map((header) => (
                    <TableCell
                        key={header.id}
                        align={header.numeric ? 'right' : 'left'}
                        padding={header.disablePadding ? 'none' : 'normal'}
                        sortDirection={
                            props.orderBy === header.id ? props.order : false
                        }
                    >
                        <TableSortLabel
                            active={props.orderBy === header.id}
                            direction={
                                props.orderBy === header.id
                                    ? props.order
                                    : 'asc'
                            }
                        >
                            {header.label}
                            <Box component="span" sx={visuallyHidden}>
                                {props.order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                            </Box>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const CommentsTableBody: React.FC<{ comments: Comment[] }> = (props) => {
    return (
        <TableBody>
            {props.comments.map((comment) => (
                <TableRow key={comment._id}>
                    {/*<TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            comment._id,
                                                    }}
                                                />
                                            </TableCell>
                                            */}
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        <a
                            href={`https://www.bluechip.link/channel/${comment.username}/home`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {comment.username}
                        </a>
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        <a
                            href={`https://www.bluechip.link/watch?v=${comment.parentId}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {comment.parentId}
                        </a>
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {new Date(comment.createDate).toISOString()}
                    </TableCell>
                    <TableCell
                        component="th"
                        id={comment._id}
                        scope="row"
                        padding="none"
                    >
                        {comment.body}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

const ChannelViewCommentsTable: React.FC = () => {
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
    };
    const numberOfCommentsQuery =
        useQuery<GetNumberOfCommentsForChannelResponse>(
            ['get-number-of-comments-for-channel'],
            async () => getNumberOfCommentsOnChannel(),
            {
                refetchInterval: false,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
            },
        );
    const commentsForChannelQuery = useQuery<GetCommentsForChannelResponse>(
        ['get-comments-for-channel', page, limit],
        async () => getCommentsForChannel({ page: page, limit: limit }),
        {
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    );
    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const comments = useMemo(() => {
        return traceSpan(
            'get-channel-comments',
            () => commentsForChannelQuery.data?.comments ?? [],
        );
    }, [commentsForChannelQuery.data]);
    return (
        <PaginatedTable
            isLoading={
                commentsForChannelQuery.isLoading ||
                numberOfCommentsQuery.isLoading
            }
            isError={
                commentsForChannelQuery.isError || numberOfCommentsQuery.isError
            }
            data={comments}
            count={numberOfCommentsQuery.data?.size ?? 0}
            header={() => (
                <ChannelViewCommentsPageTableHeader
                    orderBy={'VideoTitle'}
                    order={'asc'}
                />
            )}
            body={() => <CommentsTableBody comments={comments} />}
            loadingSkeleton={ChannelViewCommentsTableSkeleton}
            limit={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
};

export default ChannelViewCommentsTable;
